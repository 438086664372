import { PromotionalCarousel, Container } from '@news12/storybook-news'

import React from 'react'

import { PromotedContent } from '../../types'

const PromotedContentSection = ({ title, items }: PromotedContent) => {
  return (
    <Container data-testid='promoted-content' className='promoted-content' padding={{ default: '0', sm: '0 3.75rem' }}>
      <PromotionalCarousel
        title={title}
        items={items}
        // TODO: refactor handling in GA4
        // GAtrackClassName='homepage-promoted-content'
      />
    </Container>
  )
}

export default PromotedContentSection
